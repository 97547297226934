import React from "react"
import { graphql } from "gatsby"
import * as style from "./shop.module.sass"
import Layout from "../components/layout"
import FiveSix from "../components/grids/FiveSix"
const ShopPage = ({ data }) => {
  const project = data.allContentfulMoneymoney.edges

  return (
    <Layout>
      <div className={style.top} />
      <p className={style.text}>
        If you want to call some of my crafts your own, you are at the right
        place. All pieces are unique and hand made by me. get in touch if you
        see something you like. <br></br>
        <a
          href="mailto:hello@aycanoel.com"
          style={{ textDecoration: "underline" }}
        >
          hello@aycanoel.com
        </a>
      </p>
      <figure className={style.grid}>
        {project.map(project => (
          <FiveSix
            description={project.node.image.description}
            source={project.node.image.gatsbyImageData}
            title={project.node.title}
            //+ " // " + project.node.price}
          ></FiveSix>
        ))}
      </figure>
      <div className={style.box} />
    </Layout>
  )
}

export default ShopPage
export const Head = () => {
  return (
    <>
      <title>AycaNoel</title>
      <meta
        name="description"
        content="Welcome. I am a communication designer with a background in
          psychology. I create three dimensional objects and spaces - both
          digitally and physically. My work focuses around light, texture and
          feel.
          Currently, I am studying communication design at the University of
          Applied Sciences in Munich, Germany."
      />
      <meta property="og:url" content="aycanoel.com" />
      <meta property="og:site_name" content="AycaNoel" />
      <meta
        property="og:description"
        content="Welcome. I am a communication designer with a background in
          psychology. I create three dimensional objects and spaces - both
          digitally and physically. My work focuses around light, texture and
          feel.
          Currently, I am studying communication design at the University of
          Applied Sciences in Munich, Germany."
      />
    </>
  )
}
export const query = graphql`
  query {
    allContentfulMoneymoney {
      edges {
        node {
          title
          image {
            description
            gatsbyImageData
          }
        }
      }
    }
  }
`
