import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

import * as style from "./fiveSix.module.sass"

const FiveSix = ({ source, title, description }) => {
  return (
    <div>
      <GatsbyImage className={style.img} alt={description} image={source} />
      <figcaption className={style.figcaption}>{title}</figcaption>
    </div>
  )
}
export default FiveSix
